@-webkit-keyframes arrow-moving {
  from {
    opacity: 0.1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes arrow-moving {
  from {
    opacity: 0.1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.port {
  width: 100%;
}
.port > .top {
  width: 100%;
  height: 310px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 140px;
}
.port > .top > .title {
  font-weight: 700;
  font-size: 60px;
}
.port > .top > .nav-wrapper {
  position: absolute;
  bottom: 0;
  height: 47px;
  width: 100%;
  border-bottom: solid 1px #000000;
}
.port > .top > .nav-wrapper > .mb-wrapper {
  display: none;
}
.port > .top > .nav-wrapper > .wrapper {
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-template-columns: repeat(5, 94px);
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
}
.port > .top > .nav-wrapper > .wrapper > .active {
  color: #CD3325;
}
.port > .top > .nav-wrapper > .wrapper > button {
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
  font-family: mont;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.port > .top > .nav-wrapper > .wrapper > button:hover {
  font-weight: bold;
}
.port > .top > .nav-wrapper > .wrapper > .nav-bar {
  width: 94px;
  height: 3px;
  background-color: #CD3325;
  position: absolute;
  bottom: -2px;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}
.port > .list {
  min-height: 1400px;
  padding: 53px 0 282px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.port > .list > .wrapper {
  display: grid;
  grid-template-columns: repeat(3, 375px);
  gap: 53px 22px;
}
.port > .list > .wrapper > .card {
  cursor: pointer;
  -webkit-animation: arrow-moving 0.6s;
          animation: arrow-moving 0.6s;
  -webkit-animation-timing-function: cubic-bezier(0.8, -0.03, 0.31, 0.93);
          animation-timing-function: cubic-bezier(0.8, -0.03, 0.31, 0.93);
  position: relative;
}
.port > .list > .wrapper > .card .black-div {
  top: 0;
  position: absolute;
  width: 100%;
  height: 285px;
  background-color: black;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}
.port > .list > .wrapper > .card > .main-img {
  overflow: hidden;
  width: 100%;
  height: 285px;
}
.port > .list > .wrapper > .card > .main-img > img {
  height: 100%;
  width: auto;
}
.port > .list > .wrapper > .card > .main-img {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.port > .list > .wrapper > .card > .category {
  font-weight: 300;
  font-size: 15px;
  margin: 24px 0 10px 0;
}
.port > .list > .wrapper > .card > .title {
  font-weight: 700;
  font-size: 20px;
  width: 369px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.port > .list > .wrapper > .card:hover .black-div {
  opacity: 0.4;
}

@media screen and (max-width: 1365px) {
  .port > .top {
    height: 224px;
    padding-top: 90px;
  }
  .port > .top > .title {
    font-size: 40px;
  }
  .port > .top > .nav-wrapper > .wrapper {
    grid-template-columns: repeat(5, 86px);
    -webkit-column-gap: 27px;
       -moz-column-gap: 27px;
            column-gap: 27px;
  }
  .port > .top > .nav-wrapper > .wrapper > button {
    font-size: 15px;
  }
  .port > .top > .nav-wrapper > .wrapper > .nav-bar {
    width: 86px;
  }
  .port > .list {
    min-height: 960px;
    padding: 45px 0 200px 0;
  }
  .port > .list > .wrapper {
    grid-template-columns: repeat(3, 224px);
    gap: 33px 17px;
  }
  .port > .list > .wrapper > .card .black-div {
    height: 170px;
  }
  .port > .list > .wrapper > .card > .main-img {
    height: 170px;
  }
  .port > .list > .wrapper > .card > .category {
    font-size: 12px;
    margin: 7px 0;
  }
  .port > .list > .wrapper > .card > .title {
    font-size: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .port > .top {
    height: 177px;
    padding-top: 83px;
  }
  .port > .top > .title {
    font-size: 30px;
  }
  .port > .top > .nav-wrapper {
    height: 39px;
  }
  .port > .top > .nav-wrapper > .mb-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    font-size: 14px;
    font-weight: 500;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .now {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    border-bottom: solid 1px #000000;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .now > img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    right: 20px;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .now > .reverse {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .cat-wrapper {
    z-index: 100;
    background-color: white;
    position: absolute;
    display: grid;
    width: 100%;
    height: 0;
    overflow: hidden;
    grid-template-rows: repeat(5, 57px);
    -webkit-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .cat-wrapper > .active {
    font-weight: bold;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .cat-wrapper > button {
    background-color: white;
    color: black;
  }
  .port > .top > .nav-wrapper > .mb-wrapper > .open {
    height: 290px;
  }
  .port > .top > .nav-wrapper > .wrapper {
    display: none;
  }
  .port > .list {
    padding: 57px 0 160px 0;
  }
  .port > .list > .wrapper {
    grid-template-columns: 100%;
    gap: 55px 0;
  }
  .port > .list > .wrapper > .card .black-div {
    height: 244px;
  }
  .port > .list > .wrapper > .card > .main-img {
    height: 244px;
  }
  .port > .list > .wrapper > .card > .category {
    font-size: 14px;
    margin: 13px 0 9px 0;
  }
  .port > .list > .wrapper > .card > .title {
    font-size: 18px;
  }
}