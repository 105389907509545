.address-card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 39px;
     -moz-column-gap: 39px;
          column-gap: 39px;
}
.address-card > .left {
  width: 381px;
}
.address-card > .left > .title {
  font-weight: 700;
  font-size: 27px;
}
.address-card > .left > .line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 16px 0 20px 0;
}
.address-card > .left > .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
}
.address-card > .left > .content-wrapper > .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
  white-space: nowrap;
}
.address-card > .left > .content-wrapper > .content > .tag {
  width: 56px;
  font-weight: 700;
}
.address-card > .left > .content-wrapper > .content > .time {
  font-size: 400;
}
.address-card > .left > button {
  margin-top: 17px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: black;
  width: 106px;
  height: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 15px;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 17px 0 13px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.address-card > .left > button > img {
  width: 13px;
}
.address-card .map:focus-visible {
  outline: unset;
  width: 539px;
  height: 226px;
}

@media screen and (max-width: 1365px) {
  .address-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-column-gap: unset;
       -moz-column-gap: unset;
            column-gap: unset;
    row-gap: 28px;
  }
}
@media screen and (max-width: 767px) {
  .address-card {
    row-gap: 16px;
  }
  .address-card > .left {
    width: 100%;
  }
  .address-card > .left > .title {
    font-size: 20px;
  }
  .address-card > .left > .line {
    margin: 6px 0 10px 0;
  }
  .address-card > .left > .content-wrapper > .content {
    font-size: 14px;
  }
  .address-card > .left > .content-wrapper > .content > .tag {
    width: 49px;
  }
  .address-card > .left > button {
    width: 100%;
    margin-top: 31px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .address-card > .left > button > img {
    margin-right: 11px;
  }
}