.more {
  width: 100%;
  height: 862px;
  background-color: #F6F6F6;
  position: relative;
}
.more > .wrapper {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 481px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.more > .wrapper > .title {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 700;
  font-size: 35px;
}
.more > .wrapper > .list {
  left: 0;
  position: absolute;
  top: 188px;
  overflow-x: scroll;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 103px;
}
.more > .wrapper > .list > .list-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 17px;
     -moz-column-gap: 17px;
          column-gap: 17px;
}
.more > .wrapper > .list > .list-wrapper > .card {
  width: 390px;
  cursor: pointer;
}
.more > .wrapper > .list > .list-wrapper > .card > img {
  height: 297px;
}
.more > .wrapper > .list > .list-wrapper > .card > .category {
  font-weight: 300;
  font-size: 15px;
  margin: 12px 0 10px 0;
}
.more > .wrapper > .list > .list-wrapper > .card > .title {
  font-weight: 700;
  font-size: 20px;
}
.more > .wrapper > .list::-webkit-scrollbar {
  display: none;
}
.more > .wrapper > button {
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  width: 217px;
  height: 50px;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 20.7px 0 17.2px;
  font-weight: 400;
  font-size: 15px;
}
.more > .wrapper > button > img {
  width: 50px;
}
.more > .wrapper > button:hover {
  background-color: #CD3325;
}

@media screen and (max-width: 1365px) {
  .more {
    height: 560px;
  }
  .more > .wrapper {
    padding-top: 55px;
    row-gap: 312px;
  }
  .more > .wrapper > .title {
    font-size: 20px;
  }
  .more > .wrapper > .list {
    top: 105px;
    padding: 0 32px;
  }
  .more > .wrapper > .list > .list-wrapper {
    -webkit-column-gap: 9px;
       -moz-column-gap: 9px;
            column-gap: 9px;
  }
  .more > .wrapper > .list > .list-wrapper > .card {
    width: 218px;
  }
  .more > .wrapper > .list > .list-wrapper > .card > img {
    height: 166px;
  }
  .more > .wrapper > .list > .list-wrapper > .card > .category {
    margin: 16px 0 3px 0;
  }
  .more > .wrapper > .list > .list-wrapper > .card > .title {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .more {
    height: 522px;
  }
  .more > .wrapper {
    padding-top: 46px;
    row-gap: 276px;
  }
  .more > .wrapper > .title {
    font-size: 18px;
  }
  .more > .wrapper > .list {
    top: 108px;
    padding: 0 19px;
  }
  .more > .wrapper > .list > .list-wrapper > .card {
    width: 195px;
  }
  .more > .wrapper > .list > .list-wrapper > .card > img {
    height: 149px;
  }
  .more > .wrapper > .list > .list-wrapper > .card > .category {
    font-size: 13px;
    margin: 11px 0 3px 0;
  }
}