html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  color: black;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
html font[size="1"],
body font[size="1"] {
  font-size: 13px;
}
html font[size="2"],
body font[size="2"] {
  font-size: 15px;
}
html font[size="3"],
body font[size="3"] {
  font-size: 17px;
}
html font[size="4"],
body font[size="4"] {
  font-size: 19px;
}
html font[size="5"],
body font[size="5"] {
  font-size: 21px;
}
html font[size="6"],
body font[size="6"] {
  font-size: 23px;
}
html font[size="7"],
body font[size="7"] {
  font-size: 25px;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

.txt-bold {
  font-weight: 700;
}

.mainColor {
  color: var(--main);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: "pretendard", sans-serif;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}

button {
  -webkit-appearance: none;
  border: none;
}
button:focus {
  outline: none;
}

#root {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}

.wrapper {
  width: 1169px;
  margin: 0 auto;
}

@media screen and (max-width: 1365px) {
  .wrapper {
    width: 704px;
  }
}
@media screen and (max-width: 767px) {
  body {
    -webkit-touch-callout: none;
  }
  .wrapper {
    width: 320px;
  }
}