#root .finish-page {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 150px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .finish-page > img {
  width: 118.493px;
  height: 34px;
}
#root .finish-page .finish-title {
  font-size: 24px;
  margin: 24px 0 8px;
  font-weight: 700;
}
#root .finish-page .finish-sub {
  font-size: 14px;
  color: #54585A;
  font-weight: 400;
  line-height: 180%;
}
#root .finish-page > a {
  width: 320px;
  height: 50px;
  color: white;
  background-color: black;
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 45px;
}