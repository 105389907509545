.img-wrapper {
  width: 100%;
  position: relative;
}
.img-wrapper > img {
  pointer-events: none;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.img-wrapper > .resize {
  width: 100%;
}
.img-wrapper > .url {
  position: absolute;
  top: 0;
  left: 0;
}

.SMALL {
  width: 70%;
  margin: 0 auto;
}

.double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 28px;
     -moz-column-gap: 28px;
          column-gap: 28px;
}

.THREE {
  grid-template-columns: 2fr 1fr;
}

.TWO {
  grid-template-columns: 1fr 2fr;
}

.player {
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 1365px) {
  .player {
    height: 400px;
  }
  .double {
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
}
@media screen and (max-width: 767px) {
  .player {
    height: 300px;
  }
  .SMALL {
    width: 100%;
  }
  .double {
    grid-template-columns: 100%;
    row-gap: 17px;
    -webkit-column-gap: unset;
       -moz-column-gap: unset;
            column-gap: unset;
  }
}